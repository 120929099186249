import React from 'react'
import NavBar from '../../components/NavBar'
import styles from './MainPage.module.scss'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import platform from '../../imgs/platform.png'
import { Link } from 'react-router-dom';
import video from '../../imgs/video.mp4'
import Footer from '../../components/Footer'
import Game from '../../components/Game'
export default function MainPage() {
    const { t } = useTranslation()

    const contactVariants = {
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0 }
    }
    const contactVariantsX = {
        initial: { opacity: 0, x: 125 },
        animate: { opacity: 1, x: 0 }
    }
    const contactVariantsMinusX = {
        initial: { opacity: 0, x: -125 },
        animate: { opacity: 1, x: 0 }
    }
    return (
        <div className={styles.MainPage}>
            <NavBar />
            <div className={styles.header}>
                <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                    {t('welcomehomeheader')} ExchangeUnity
                </motion.h1>
                <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.6 }}>
                    {t('welcome_home_body')}
                </motion.p>
                <motion.a href='https://trade.exchangeunity.com/login' className={styles.Cabinet} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.9 }}>
                    {t('join_us')}
                </motion.a>
            </div>
            <div className={styles.platformImg}>
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 1.2 }} className={styles.img}>
                    <img src={platform} alt="platform" width={'60%'} />
                </motion.div>
            </div>
            <div className={styles.section1}>
                <div className={styles.section1Body}>
                    <motion.h1
                        initial={'initial'}
                        whileInView={'animate'}
                        transition={{ duration: 0.7 }}
                        variants={contactVariants}
                    >
                        {t('section1_h1')}
                    </motion.h1>
                    <motion.p
                        initial={'initial'}
                        whileInView={'animate'}
                        transition={{ duration: 0.7 }}
                        variants={contactVariants}
                    >
                        {t('section1_p')}
                    </motion.p>
                </div>
            </div>
            <div className={styles.section2}>
                <div className={styles.section2Body}>
                    <motion.div
                        className={styles.section2Video}
                        initial={'initial'}
                        whileInView={'animate'}
                        transition={{ duration: 0.7 }}
                        variants={contactVariantsMinusX}
                    >
                        <video controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar">
                            <source src={video} type="video/mp4"></source>
                        </video>
                    </motion.div>
                    <motion.div
                        className={styles.section2Text}
                        initial={'initial'}
                        whileInView={'animate'}
                        transition={{ duration: 0.7 }}
                        variants={contactVariantsX}
                    >
                        <h1>{t('section2_h1')}</h1>
                        <p>{t('section2_p')}</p>
                    </motion.div>
                </div>
            </div>
            <div className={styles.section3}>
                <motion.div className={styles.section3Body} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                    <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.6 }}>
                    {t('section3_h1')}
                    </motion.h1>
                    <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.9 }}>
                    {t('section3_p')}
                    </motion.p>
                    <Game/>
                </motion.div>
            </div>
            <div className={styles.section4}>
                <div className={styles.section4Body}>
                    <div className={styles.section4Text}>
                    <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.6 }}>{t('about_title')}</motion.h1>
                    <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.9 }}>{t('about_subtitle')}</motion.p>
                    </div>
                    <div className={styles.section4Blocks}>
                        <div className={styles.advantages1}>
                            <div className={styles.img}>

                            </div>                            
                            <div className={styles.text}>
                                <h3>{t('section1_card3_title_1')}</h3>
                                <p>{t('section1_card3_body')}</p>
                            </div>
                        </div>
                        <div className={styles.advantages2}>
                            <div className={styles.img}>

                            </div>                            
                            <div className={styles.text}>

                                <h3>{t('section4_card2_h3')}</h3>
                                <p>{t('section4_card2_p')}</p>
                            </div>
                        </div>
                        <div className={styles.advantages3}>
                            <div className={styles.img}>

                            </div>                            
                            <div className={styles.text}>
                                <h3>{t('about_card4_title')}</h3>
                                <p>{t('about_card4_body')}</p>
                            </div>
                        </div>
                        <div className={styles.advantages4}>
                            <div className={styles.img}>

                            </div>                            
                            <div className={styles.text}>
                                <h3>{t('about_card5_title')}</h3>
                                <p>{t('about_card5_body')}</p>

                            </div>
                        </div>
                        <div className={styles.advantages5}>
                            <div className={styles.img}>

                            </div>                            
                            <div className={styles.text}>
                                <h3>{t('about_card2_title')}</h3>
                                <p>{t('about_card2_body')}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
