import { Route, Routes } from 'react-router-dom';
import './App.css';
import MainPage from './pages/MainPage/MainPage';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

function App() {
  const {i18n} = useTranslation()
  useEffect(()=>{
    if(secureLocalStorage.getItem('lang')){
      i18n.changeLanguage(secureLocalStorage.getItem('lang'));
    }
    else{
      i18n.changeLanguage("ru");
      secureLocalStorage.setItem("lang","ru");
    }
  },[])
  return (
    <div className="App">
        <Routes>
            <Route path='/' element={<MainPage/>}></Route>
        </Routes>
    </div>
  );
}

export default App;
