import React, { useState, useRef, useEffect } from "react";
import {
    SciChartSurface,
    NumberRange,
    NumericAxis,
    OhlcDataSeries,
    FastCandlestickRenderableSeries,
    ZoomPanModifier,
    ZoomExtentsModifier,
    MouseWheelZoomModifier,
    ENumericFormat,
    DateTimeNumericAxis,
    EAutoRange,
    CursorModifier,
    EDataSeriesType,
    FastOhlcRenderableSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    AxisMarkerAnnotation,
    ELineDrawMode,
    libraryVersion,
    easing,
} from "scichart";
import { CircularProgress } from '@mui/material';
import { appTheme } from "scichart-example-dependencies";
import classes from "../styles/Graph.module.scss";
import moment from "moment";
import logo from '../imgs/logo.png'
import dayjs from "dayjs";
import scichart2d from 'scichart/_wasm/scichart2d.data'
import scichart2dWasm from 'scichart/_wasm/scichart2d.wasm'
import styles from '../styles/Graph.module.scss'
import {historyetc} from './historyetc';
import {historygold} from './historygold';
import {historybtc} from './historybtc';
import Swal from 'sweetalert2'
const divElementId = "chart";
const Y_AXIS_VOLUME_ID = "Y_AXIS_VOLUME_ID";

const drawExample = async (history,active) => {
    SciChartSurface.configure({
        dataUrl: scichart2d,
        wasmUrl: scichart2dWasm
      });
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(divElementId, {
        theme: appTheme.SciChartJsTheme
    });
    sciChartSurface.background = "#161C24";
    const xAxis = new DateTimeNumericAxis(wasmContext, {
        autoRange: EAutoRange.Never,
        drawMajorTickLinesProperty:false,
        drawMajorGridLines:true,
        drawMinorTickLinesProperty:false,
        drawMinorGridLines: false,
        majorGridLineStyle: {color:"rgba(82, 83, 83, 0.136)", strokeDashArray:ELineDrawMode.Solid,strokeThickness: 1},
    });
    sciChartSurface.xAxes.add(xAxis);

    const yAxis = new NumericAxis(wasmContext, {
        growBy: new NumberRange(0.1, 0.1),
        labelFormat: ENumericFormat.Decimal,
        labelPrecision: 2,
        labelPrefix: "$",
        autoRange: EAutoRange.Always,
        drawMajorTickLinesProperty:false,
        drawMajorGridLines:  true,
        drawMinorTickLinesProperty:false,
        drawMinorGridLines:  false,
        majorGridLineStyle: {color:"rgba(82, 83, 83, 0.136)", strokeDashArray:ELineDrawMode.Solid,strokeThickness: 1},
    });
    sciChartSurface.yAxes.add(yAxis);

    sciChartSurface.yAxes.add(
        new NumericAxis(wasmContext, {
            id: Y_AXIS_VOLUME_ID,
            growBy: new NumberRange(0, 4),
            isVisible: false,
            autoRange: EAutoRange.Always
        })
    );

    const endDate = new Date(Date.now());
    const startDate = new Date();
    startDate.setHours(endDate.getHours() - 300);
    const priceBars = history;

    const xValues = [];
    const openValues = [];
    const highValues = [];
    const lowValues = [];
    const closeValues = [];
    const volumeValues = [];
    priceBars.forEach(priceBar => {
        xValues.push(moment(priceBar.time).unix());
        openValues.push(priceBar.open);
        highValues.push(priceBar.high);
        lowValues.push(priceBar.low);
        closeValues.push(priceBar.close);
        volumeValues.push(priceBar.volume);
    });

    const startViewportRange = new Date();
    startViewportRange.setHours(startDate.getHours() - 100);
    xAxis.visibleRange = new NumberRange(startViewportRange.getTime() / 1000, endDate.getTime() / 1000);

    const candleDataSeries = new OhlcDataSeries(wasmContext, {
        xValues,
        openValues,
        highValues,
        lowValues,
        closeValues,
        dataSeriesName: active
    });
    const candlestickSeries = new FastCandlestickRenderableSeries(wasmContext, {
        dataSeries: candleDataSeries,
        stroke: appTheme.ForegroundColor,
        strokeThickness: 1,
        brushUp: "#6dd881",
        brushDown: "#ff5630",
        strokeUp: "#6dd881",
        strokeDown: "#ff5630"
    });
    sciChartSurface.renderableSeries.add(candlestickSeries);

    const ohlcSeries = new FastOhlcRenderableSeries(wasmContext, {
        dataSeries: candleDataSeries,
        stroke: appTheme.ForegroundColor,
        strokeThickness: 1,
        dataPointWidth: 0.9,
        strokeUp: appTheme.VividGreen,
        strokeDown: appTheme.MutedRed,
        isVisible: false
    });
    sciChartSurface.renderableSeries.add(ohlcSeries);

    sciChartSurface.chartModifiers.add(
        new ZoomExtentsModifier(),
        new ZoomPanModifier(),
        new CursorModifier({
            crosshairStroke: appTheme.VividOrange,
            axisLabelFill: appTheme.VividOrange,
            tooltipLegendTemplate: getTooltipLegendTemplate
        })
    );

    return { sciChartSurface, candlestickSeries, ohlcSeries };
};



const getTooltipLegendTemplate = (seriesInfos, svgAnnotation) => {
    let outputSvgString = "";

    seriesInfos.forEach((seriesInfo, index) => {
        const y = 20 + index * 20;
        const textColor = seriesInfo.stroke;
        let legendText = seriesInfo.formattedYValue;
        if (seriesInfo.dataSeriesType === EDataSeriesType.Ohlc) {
            const o = seriesInfo;
            legendText = `Open=${o.formattedOpenValue} High=${o.formattedHighValue} Low=${o.formattedLowValue} Close=${o.formattedCloseValue}`;
        }
        outputSvgString += `<text x="8" y="${y}" font-size="13" font-family="Verdana" fill="${textColor}">
            ${seriesInfo.seriesName}: ${legendText}
        </text>`;
    });

    return `<svg width="100%" height="100%">
                ${outputSvgString}
            </svg>`;
};


function IntervalSelector({ interval,onIntervalChange,buttonBlocked,Up,Down }) {
    const buttonStyle={
        backgroundColor: '#000',
        padding: '8px 14px',
        color:'#A1A1AA',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        border:'1px solid #4e9cd85e',
        borderRadius: '16px',
        fontFamily: '"Inter Tight", sans-serif'
    }
    const buttonStyleActive={
        backgroundColor: '#000',
        padding: '8px 14px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        border:'1px solid #4e9cd85e',
        borderRadius: '16px',
        fontFamily: '"Inter Tight", sans-serif',
        boxShadow: '0px 0px 5px -1px rgba(82,165,230,0.62)',
        color:'#fff'
    }
    return (
        <div style={{width:'100%' ,flexWrap:'wrap',padding:'5px 10px 10px 0' , display:'flex' , alignItems:'center' ,justifyContent:'space-between',gap:'20px'}}>
            <div style={{ height:'30px' , display:'flex' , alignItems:'center',gap:'10px'}}>
            <button onClick={()=>{onIntervalChange('Bitcoin')}} disabled={buttonBlocked} style={interval=='Bitcoin'?buttonStyleActive:buttonStyle}>Bitcoin (BTC)</button>
            <button onClick={()=>{onIntervalChange('Gold')}} disabled={buttonBlocked} style={interval=='Gold'?buttonStyleActive:buttonStyle}>Gold (GC1:COM)</button>
            <button onClick={()=>{onIntervalChange('Ethereum')}} disabled={buttonBlocked} style={interval=='Ethereum'?buttonStyleActive:buttonStyle}>Ethereum (ETH)</button>
            </div>
            <div style={{ height:'30px',paddingRight:'10px' , display:'flex' , alignItems:'right' , justifyContent:'center',gap:'20px'}}>
                <button className={styles.pulseBtnUp}  onClick={Up} disabled={buttonBlocked}>Up</button>
                <button className={styles.pulseBtn}   onClick={Down} disabled={buttonBlocked}>Down</button>
            </div>
        </div>
    );
}

function Game() {
    //example
    const [buttonBlocked,setButtonBlocked] = useState(false);
    const [active,setActive] = useState('Bitcoin');
    const sciChartSurfaceRef = useRef();
    const [interval, setInterval] = useState(60);
    const [candlestickChartSeries, setCandlestickChartSeries] = useState();
    const [ohlcChartSeries, setOhlcChartSeries] = useState();
    //code
    const [history,setHistory] = useState(historybtc)
    const [isLoadingChart, setIsLoadingChart] = useState(false);
    const changeHistory = (messageData,type) => {
        setIsLoadingChart(true);
        setHistory(messageData);
        if(sciChartSurfaceRef){
            const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
            const lastDataPointTimestamp = history[history.length - 1].time; 
            const zoomRangeSeconds = interval; // Переводим часы в секунды
            const visibleRangeMin = lastDataPointTimestamp - zoomRangeSeconds;
            const visibleRangeMax = lastDataPointTimestamp;
            xAxis.visibleRange = new NumberRange(visibleRangeMin, visibleRangeMax);
            sciChartSurfaceRef.current.zoomExtents();
        }
    }
    const handleIntervalChange = (label) => {
        setActive(label);
    };
    const [originalData, setOriginalData] = useState({
        valueslow: null,
        valueshigh: null,
        valuesclose: null,
        valuesopen: null,
        valuesx: null
    });
    function generateCandles(historyold,lastValue,interval,type) {
        const candles = [];
        let trend;
        let temp = 0;
        setButtonBlocked(true);
        const intervalInMilliseconds = 1000*60*60*24*7; // интервал в миллисекундах: 1 минута или 1 день
        let count = 60;
        for (let i = 1; i <= count; i++) {
            setTimeout(()=>{
                let randomDirection = Math.random();
                if (randomDirection < 0.40) {
                    trend = -1; // свечи падают
                } else {
                    trend = 1; // свечи растут
                }
                const open = lastValue;
                let closeMultiplier =  type=='btc'?5000:120;
                let highlowMultiplier =  type=='btc'?1000:80;
                const close = open + trend * (Math.random() * closeMultiplier);
                const high = Math.max(open, close) + (Math.random() * highlowMultiplier);
                const low = Math.min(open, close) - (Math.random() * highlowMultiplier);
                
                const time = moment(historyold[historyold.length - 1].time).utc(false).unix()*1000 + (i*intervalInMilliseconds);
                temp = {
                    time:moment(time).utc(false).unix(),open:open,high:high,low:low,close:close
                }
                sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(moment(time).utc(false).unix(),open,high,low,close);
                const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                const visibleRange = xAxis.visibleRange;
                if (time > visibleRange.max*1000) {
                    const maxVisibleRange = moment(time).utc(false).unix();
                    const minVisibleRange = maxVisibleRange - (visibleRange.max - visibleRange.min); // Сдвигаем начало диапазона так же, как и конец
                    xAxis.visibleRange = new NumberRange(minVisibleRange, maxVisibleRange);
                }
                // candles.push({ open, high, low, close, time });
                lastValue = close; // обновляем lastValue для следующей свечи
                if(i==count){
                    if(historyold[historyold.length -1].close < temp.close){
                          Swal.fire({
                              title: "Good job!",
                              text: `You won ${parseFloat(temp.close-historyold[historyold.length -1].close).toFixed(2)} $`,
                              icon: "success",
                              color: '#fff',
                              background: '#161C24',
                              buttonsStyling: false,
                              confirmButtonText:'Try it',
                              customClass: {
                                  confirmButton: 'Custom_Confirm',
                              }
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                  window.location.href='https://trade.exchangeunity.com/register'
                              }
                          });
                    }
                    const dataSeries = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries;
                    dataSeries.clear();
                    dataSeries.appendRange(
                        historyold.map(item=>moment(item.time).unix()), 
                        historyold.map(item=>item.open),
                        historyold.map(item=>item.high),
                        historyold.map(item=>item.low),
                        historyold.map(item=>item.close),
                    );
                    const xes = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeXValues();
                    const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                    const firstDataPointTimestamp = dayjs(moment(history[0].time).utc(false)).valueOf() / 1000;
                    const lastDataPointTimestamp = dayjs(moment(history[history.length - 1]).utc(false)).valueOf() / 1000;
                    let zoomRangeSeconds, mod;
                    let timediff = lastDataPointTimestamp - firstDataPointTimestamp;
                    
                        zoomRangeSeconds = 1 * 60 * 60 * 24 * 7; // Недельный интервал
                        mod = 300; // Отображаем 1 неделю вперед и назад от последней свечи
                    
                        const halfZoomRangeSeconds = zoomRangeSeconds * mod ;
                        
                        // const visibleRangeMin = firstDataPointTimestamp + zoomRangeSeconds;
                        // const visibleRangeMax = lastDataPointTimestamp + halfZoomRangeSeconds;
                        
                        const visibleRangeMin = firstDataPointTimestamp + timediff / 1.15;
                        const visibleRangeMax = lastDataPointTimestamp + timediff / 15;
                        
                        const rangeLength = visibleRangeMax - visibleRangeMin;
                        const middleTimestamp = visibleRangeMin + rangeLength / 2;
                        
                        const newVisibleRangeMin = middleTimestamp - halfZoomRangeSeconds;
                        const newVisibleRangeMax = middleTimestamp + halfZoomRangeSeconds;
                        
                        xAxis.visibleRange = new NumberRange(visibleRangeMin, visibleRangeMax);
                    setButtonBlocked(false);
                }
            },i*200)
        } 
    }
    function generateCandlesDown(historyold,lastValue,interval,type) {
        const candles = [];
        let trend;
        let temp = 0;
        setButtonBlocked(true);
        const intervalInMilliseconds =  1000*60*60*24*7; // интервал в миллисекундах: 1 минута или 1 день
        let count = 60;
        for (let i = 1; i <= count; i++) {
            setTimeout(()=>{
                let randomDirection = Math.random();
                if (randomDirection < 0.6) {
                    trend = -1;
                } else {
                    trend = 1;
                }
                const open = lastValue;
                let closeMultiplier =  type=='btc'?5000:120;
                let highlowMultiplier =  type=='btc'?1000:80;
                const close = open + trend * (Math.random() * closeMultiplier);
                const high = Math.max(open, close) + (Math.random() * highlowMultiplier);
                const low = Math.min(open, close) - (Math.random() * highlowMultiplier);
                
                const time = moment(historyold[historyold.length - 1].time).utc(false).unix()*1000 + (i*intervalInMilliseconds);
                temp = {
                    time:moment(time).utc(false).unix(),open:open,high:high,low:low,close:close
                }
                sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(moment(time).utc(false).unix(),open,high,low,close);
                const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                const visibleRange = xAxis.visibleRange;
                if (time > visibleRange.max*1000) {
                    const maxVisibleRange = moment(time).utc(false).unix();
                    const minVisibleRange = maxVisibleRange - (visibleRange.max - visibleRange.min); // Сдвигаем начало диапазона так же, как и конец
                    xAxis.visibleRange = new NumberRange(minVisibleRange, maxVisibleRange);
                }
                lastValue = close;
                if(i==count){
                    if(historyold[historyold.length -1].close > temp.close){
                        Swal.fire({
                            title: "Good job!",
                            text: `You won ${parseFloat(historyold[historyold.length -1].close - temp.close).toFixed(2)} $`,
                            icon: "success",
                            color: '#fff',
                            background: '#161C24',
                            buttonsStyling: false,
                            confirmButtonText:'Try it',
                            customClass: {
                                confirmButton: 'Custom_Confirm',
                            }
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                                window.location.href='https://trade.exchangeunity.com/register'
                            }
                        });
                    }
                    const dataSeries = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries;
                    dataSeries.clear();
                    dataSeries.appendRange(
                        historyold.map(item=>moment(item.time).unix()), 
                        historyold.map(item=>item.open),
                        historyold.map(item=>item.high),
                        historyold.map(item=>item.low),
                        historyold.map(item=>item.close),
                    );
                    const xes = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeXValues();
                    const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                    const firstDataPointTimestamp = dayjs(moment(history[0].time).utc(false)).valueOf() / 1000;
                    const lastDataPointTimestamp = dayjs(moment(history[history.length - 1]).utc(false)).valueOf() / 1000;
                    let zoomRangeSeconds, mod;
                    let timediff = lastDataPointTimestamp - firstDataPointTimestamp;
                    
                    
                        zoomRangeSeconds = 1 * 60 * 60 * 24 * 7; // Недельный интервал
                        mod = 300; // Отображаем 1 неделю вперед и назад от последней свечи
                    
                        const halfZoomRangeSeconds = zoomRangeSeconds * mod ;
                        
                        // const visibleRangeMin = firstDataPointTimestamp + zoomRangeSeconds;
                        // const visibleRangeMax = lastDataPointTimestamp + halfZoomRangeSeconds;
                        
                        const visibleRangeMin = firstDataPointTimestamp + timediff / 1.15;
                        const visibleRangeMax = lastDataPointTimestamp + timediff / 15;
                        
                        const rangeLength = visibleRangeMax - visibleRangeMin;
                        const middleTimestamp = visibleRangeMin + rangeLength / 2;
                        
                        const newVisibleRangeMin = middleTimestamp - halfZoomRangeSeconds;
                        const newVisibleRangeMax = middleTimestamp + halfZoomRangeSeconds;
                        
                        xAxis.visibleRange = new NumberRange(visibleRangeMin, visibleRangeMax);
                    
                    setButtonBlocked(false);
                }
            },i*200)
        }
    }
    useEffect(()=>{
        if(history?.length>0){
            setIsLoadingChart(false)
            const chartInitializationPromise = drawExample(history,active).then(
                ({ sciChartSurface,  candlestickSeries, ohlcSeries }) => {
                    setCandlestickChartSeries(candlestickSeries);
                    setOhlcChartSeries(ohlcSeries);
                    sciChartSurfaceRef.current = sciChartSurface;
                    sciChartSurfaceRef.current.watermarkPropertyPosition.x = 1;
                    sciChartSurfaceRef.current.watermarkPropertyPosition.y = 1;
                    sciChartSurfaceRef.current.watermarkProperties.$$.count.value = -1;
                    sciChartSurfaceRef.current.watermarkProperties.$$.ptr = 1;
                    sciChartSurfaceRef.current.watermarkProperties.$$.m_fCanvasWidth = 0;
                    
                    sciChartSurfaceRef.current.watermarkPropertyPosition.$$.count.value = -1;
                    sciChartSurfaceRef.current.watermarkPropertyPosition.$$.ptr = 1;
                    sciChartSurfaceRef.current.watermarkRelativeToCanvas = false;
                    setOriginalData({
                        valueslow: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeLowValues(),
                        valueshigh: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeHighValues(),
                        valuesclose: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeCloseValues(),
                        valuesopen: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeOpenValues(),
                        valuesx: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeXValues()
                    });
                    const xes = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeXValues();
                    const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                    const firstDataPointTimestamp = dayjs(moment(history[0].time).utc(false)).valueOf() / 1000;
                    const lastDataPointTimestamp = dayjs(moment(history[history.length - 1]).utc(false)).valueOf() / 1000;
                    let zoomRangeSeconds, mod;
                    const now = dayjs(moment(new Date(Date.now())).utc(false)).valueOf();
                    let timediff = lastDataPointTimestamp - firstDataPointTimestamp;
                    // console.log(moment(firstDataPointTimestamp).format("YYYY-MM-DD HH:mm:ss"));
                    // console.log(moment(lastDataPointTimestamp).format("YYYY-MM-DD HH:mm:ss"));
                    // console.log(moment(now).format("YYYY-MM-DD HH:mm:ss"));
                    // console.log(timediff)
                  
                        zoomRangeSeconds = 1 * 60 * 60 * 24 * 7; // Недельный интервал
                        mod = 300; // Отображаем 1 неделю вперед и назад от последней свечи
                    
                        const halfZoomRangeSeconds = zoomRangeSeconds * mod ;
                        
                        // const visibleRangeMin = firstDataPointTimestamp + zoomRangeSeconds;
                        // const visibleRangeMax = lastDataPointTimestamp + halfZoomRangeSeconds;
                        
                        const visibleRangeMin = firstDataPointTimestamp + timediff / 1.15;
                        const visibleRangeMax = lastDataPointTimestamp + timediff / 15;
                        
                        const rangeLength = visibleRangeMax - visibleRangeMin;
                        const middleTimestamp = visibleRangeMin + rangeLength / 2;
                        
                        const newVisibleRangeMin = middleTimestamp - halfZoomRangeSeconds;
                        const newVisibleRangeMax = middleTimestamp + halfZoomRangeSeconds;
                        
                        xAxis.visibleRange = new NumberRange(visibleRangeMin, visibleRangeMax);
                    
                }
            );
            return () => {
                if (sciChartSurfaceRef.current) {
                    sciChartSurfaceRef.current.delete();
                    sciChartSurfaceRef.current = undefined;
                    return;
                }
                chartInitializationPromise.then(() => {
                    sciChartSurfaceRef.current.delete();
                    sciChartSurfaceRef.current = undefined;
                });
            };
        }
    },[history])
    
    useEffect(() => {
        if(active === "Bitcoin")
        setHistory(historybtc);
        if(active === "Ethereum")
        setHistory(historyetc);
        if(active === "Gold")
        setHistory(historygold);
        
    }, [active]);
    const Up = () => {
        if(active === "Bitcoin"){
            const btcLastValue = 61171.7;
            generateCandles(history,btcLastValue,"1w","btc")
        }
        if(active === "Ethereum"){
            const ethLastValue = 2635.51;
            generateCandles(history,ethLastValue,"1w","eth")
        }
        if(active === "Gold"){
            const goldLastValue = 2534.3999023438;
            generateCandles(history,goldLastValue,"1w","gold")
        }
    }
    const Down = () => {
        if(active === "Bitcoin"){
            const btcLastValue = 61171.7;
            generateCandlesDown(history,btcLastValue,"1w","btc")
        }
        if(active === "Ethereum"){
            const ethLastValue = 2635.51;
            generateCandlesDown(history,ethLastValue,"1w","eth")
        }
        if(active === "Gold"){
            const goldLastValue = 2534.3999023438;
            generateCandlesDown(history,goldLastValue,"1w","gold")
        }
    }
    return (
        <React.Fragment>
            <div className={classes.FullHeightChartWrapper}>
            <IntervalSelector onIntervalChange={handleIntervalChange} buttonBlocked={buttonBlocked} interval={active} Up={Up} Down={Down}/>
            <div style={{width:"100%",height:"100%",display:isLoadingChart?"flex":"none",alignItems:"center",justifyContent:"center", backgroundColor:"transparent",position:"absolute",zIndex:"999999",top:"1vh" }}>
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#4FA0DF' : '#4FA0DF'),
                        animationDuration: '1450ms',
                    }}
                    size={80}
                    thickness={4}
                />
            </div> 
            <div style={{ display:!isLoadingChart?"flex":"none", flexDirection: "column", height: "calc(100% - 40px)", width: "100%" }}>
                    <div id={divElementId} style={{ flexBasis: "100%", flexGrow: 1, flexShrink: 1 }} />
                {/* <div id={divOverviewId} style={{ flexBasis: "20%", flexGrow: 1, flexShrink: 1 }} /> */}
                    
                    <div className='waterMark'>
                            <img src={logo} alt='Logo' width={62} height={65}/>
                            <span>ExchangeUnity</span>
                    </div>
            </div>
            </div>
        </React.Fragment>
    );
}

export default Game;