import React from 'react'
import styles from '../styles/Footer.module.scss'
import { useTranslation } from 'react-i18next'
export default function Footer() {
    const {t} = useTranslation()
  return (
    <div className={styles.Footer}>
        <div className={styles.FooterBody}>
            <h4>© EU Trade Limited. All rights Reserved</h4>
            <p>{t('footer_risk')}</p>
        </div>
    </div>
  )
}
