import React from 'react'
import styles from '../styles/NavBar.module.scss'
import logo from '../imgs/logo.png'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n/config';
import secureLocalStorage from 'react-secure-storage'
export default function NavBar() {
    const {t} = useTranslation();
    const {pathname} = useLocation()
    function changeLanguage(e) {
        secureLocalStorage.setItem("lang",e);
        i18n.changeLanguage(e);
      }
  return (
    <div className={styles.NavBar}>
        <div className={styles.NavBarContent}>
            <div className={styles.Logo}>
                <img src={logo} alt="Logo" width={30}/>
                <h4>ExchangeUnity</h4>
            </div>
            <div className={styles.Navigation}>
                {
                    secureLocalStorage.getItem('lang') == 'ru'
                    ?<span onClick={()=>changeLanguage('en')} className={styles.Cabinet}>RU</span>
                    :<span onClick={()=>changeLanguage('ru')} className={styles.Cabinet}>EN</span>
                }
                <Link to='https://trade.exchangeunity.com/login' className={styles.Cabinet}>{t('join_us')}</Link>
            </div>
        </div>
    </div>
  )
}
